import {Http} from "../../infrastructure/http"

const http = new Http()

export const handleFindOrder = (ref, callback) => {

    return new Promise((resolve, reject) => {
        http.get(
            "public/orders/findByRef/" + ref,
            {},
            (err, res) => {
                err
                    ? reject(err)
                    : resolve({
                        state: "success",
                        data: res.data
                    })
            }
        )
    });
}
